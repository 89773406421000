<template>
  <div>
    <b-row>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="form.name"
          class="mb-2"
          :isValidate="v.form.name.$error"
          :v="v.form.name"
        />
      </b-col>
      <b-col
        xs
        sm="12"
        md
        lg
        xl="6"
        :class="chkValid == 1 ? 'display-only' : ''"
      >
        <div>
          <label>Discount <span class="text-error">*</span></label>
          <div class="input-group" style="margin-bottom: 15px">
            <input
              type="number"
              class="form-control"
              placeholder="Discount"
              max
              v-model="form.promotion_value"
              :disabled="chkValid == 1 ? true : false"
              @keypress="isNumber($event)"
              oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
              @paste.prevent
            />
            <div class="input-group-append">
              <button
                class="input-group-text"
                v-if="percentOrbath"
                :disabled="chkValid == 1 ? true : false"
                @click.prevent="getPercentClick(false)"
              >
                <font-awesome-icon :icon="['fas', 'percent']" class="percent" />
              </button>
              <button
                class="input-group-text"
                v-else
                :disabled="chkValid == 1 ? true : false"
                @click.prevent="getPercentClick(true)"
              >
                <span class="percent">Baht</span>
              </button>
            </div>
          </div>
          <div v-if="v.form.promotion_value.$error">
            <div class="input-error" v-if="!v.form.promotion_value.required">
              Please input.
            </div>
            <div
              class="input-error"
              v-else-if="!v.form.promotion_value.isValidPromo"
            >
              Discount value must less than or equal to 100%
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Short Description -->
    <b-row>
      <b-col>
        <InputTextArea
          textFloat="Description"
          placeholder="Description"
          :value="form.short_description"
          isRequired
          :v="v.form.short_description"
          :isValidate="v.form.short_description.$error"
          v-model="form.short_description"
          rows="3"
        />
      </b-col>
    </b-row>
    <!-- Description -->
    <b-row>
      <b-col>
        <TextEditorsTiny
          textFloat="Detail"
          placeholder="Detail"
          :value="form.description"
          @onDataChange="
            (val) => {
              form.description = val;
            }
          "
          isRequired
          v-model="form.description"
          :v="v.form.description"
          :isValidate="v.form.description.$error"
        />
      </b-col>
    </b-row>
    <!-- Cover Image -->
    <b-row>
      <b-col md="6">
        <UploadFile
          v-model="form.image_url"
          :value="form.image_url"
          textFloat="Cover Image"
          placeholder="Please Select a File To Upload."
          format="image"
          name="thumbnail"
          accept="image/jpeg, image/png"
          text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
          isRequired
          v-on:onFileChange="onImageChange"
          :fileName="form.image_url"
          :isValidate="v.form.image_url.$error"
          :v="v.form.image_url"
        />
        <PreviewBox
          v-on:delete="onDeleteImage"
          :disabled="isDisable"
          :showPreviewType="0"
          :showPreview="showPreview"
          :isLoadingImage="isLoadingImage"
          :ratioType="1"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Number Of Privileges"
          placeholder="Number Of Privileges"
          type="number"
          name="max_quantity"
          isRequired
          v-model="form.max_quantity"
          :v="v.form.max_quantity"
        />
      </b-col>
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Minimum Transaction Value (Baht)"
          placeholder="Minimum Transaction Value (Baht)"
          type="number"
          name="condition"
          v-model="form.minimum_amount_requirement"
          :v="v.form.minimum_amount_requirement"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col lg="6" md="6" sm="12" xs="12">
        <b-form-group label="Extra Point">
          <b-form-radio-group
            v-model="form.extra_point_type_id"
            :options="extraOptions"
            name="extra-point"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <label>
          {{ form.extra_point_type_id == 1 ? "Number of Multiplier" : "Point" }}
          <span class="text-error">*</span></label
        >
        <div class="input-group">
          <!-- <div class="input-group-prepend">
            <button
              class="input-group-text padding-multiple"
              v-if="multiplyPoint"
              :disabled="chkValid == 1 ? true : false"
              @click.prevent="multiplyPoint = false"
            >
              <span class="icon-multiple">+</span>
            </button>
            <button
              class="input-group-text padding-multiple"
              v-else
              :disabled="chkValid == 1 ? true : false"
              @click.prevent="multiplyPoint = true"
            >
              <span class="icon-multiple">x</span>
            </button>
          </div> -->
          <input
            type="number"
            class="form-control"
            @change="onAddPoint"
            :onlyNumber="true"
            :disabled="chkValid == 1 ? true : false"
            :placeholder="
              form.extra_point_type_id == 2
                ? 'Points To Be Earned (After Redeemed Coupon)'
                : 'Number of Multiplier'
            "
            v-model="pointShow"
            oninput="if( this.value.length > 5 ) this.value =
          this.value.slice(0,5)"
            @keypress="isNumber($event)"
            @paste.prevent
          />
        </div>
      </b-col>
    </b-row>

    <div v-if="v.form.point.$error || v.form.multiply_point.$error">
      <span class="text-error">Please input text.</span>
    </div>
    <!-- Prefix -->
    <b-row class="mt-3">
      <b-col xs sm="12" md lg xl="6">
        <div class="content-between">
          <label class="mb-2 label-text">
            Coupon User Group
            <span class="text-error">*</span>
          </label>

          <b-form-checkbox
            v-model="form.display_only_in_booth"
            :value="1"
            :unchecked-value="0"
            >Display In Booth Only</b-form-checkbox
          >
        </div>

        <InputSelect
          title=""
          name="Coupontype"
          isRequired
          :options="availabilityList"
          :v="v.form.availability"
          v-model="v.form.availability.$model"
          :isValidate="v.form.availability.$error"
          :disabled="chkValid == 1 || form.coupon_code_type == 2"
          valueField="value"
          textField="text"
        >
          <template v-slot:option-first>
            <b-form-select-option :value="null" disabled
              >--- Please Select Type ---</b-form-select-option
            >
          </template>
        </InputSelect>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col xs sm="12" md lg xl="6">
        <b-form-group label="Coupon Type Code" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="coupon-type"
            v-model="form.coupon_code_type"
            :options="couponTypeOptions"
            :aria-describedby="ariaDescribedby"
            name="coupon-type-options"
            @change="handleTypeChange"
            :disabled="chkValid == 1 ? true : false"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="form.coupon_code_type == 2">
      <b-col xs sm="12" md lg xl="6">
        <InputText
          textFloat="Promotion Ref."
          placeholder="Promotion Ref."
          type="text"
          name="promotion-ref"
          isRequired
          :isValidate="v.form.promotion_ref.$error"
          v-model="form.promotion_ref"
          :v="v.form.promotion_ref"
        />
      </b-col>
      <div class="break-normal"></div>
      <b-col xs sm="12" md lg xl="6">
        <div class="content-between">
          <label class="label-text"
            >Upload Code <span class="text-error">*</span></label
          >
          <b-button variant="link" @click="downloadTemplate()"
            >Download Template</b-button
          >
        </div>
        <UploadFileV2
          v-if="!isUpload"
          textFloat=""
          placeholder="Please Choose File"
          format="excel"
          name="file"
          text="*Please upload only file .xlsx less than 10 MB"
          isRequired
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :fileName="form.coupon_code_import_name"
          v-model="form.coupon_code_import"
          :isValidate="v.form.coupon_code_import_name.$error"
          :v="v.form.coupon_code_import_name"
          id="uploadfile"
          :disabled="chkValid == 1 ? true : false"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3" v-else>
      <b-col xs sm="12" md lg xl="6">
        <div class="content-between">
          <label class="mb-2 label-text">
            Coupon Code {{ form.is_random_prefix == 1 ? "(Prefix)" : "" }}
            <span class="text-error">*</span>
          </label>

          <b-checkbox
            v-if="form.availability == 0"
            v-model="form.is_random_prefix"
            :value="1"
            :unchecked-value="0"
            >Random Code</b-checkbox
          >
        </div>
        <InputText
          textFloat=""
          placeholder="Coupon Code"
          type="text"
          name="prefix"
          isRequired
          :isValidate="v.form.prefix.$error"
          v-model="form.prefix"
          :v="v.form.prefix"
          @onKeypress="onKeypressArticleNo"
        />
      </b-col>
    </b-row>

    <template v-if="form.availability == 0">
      <b-row>
        <b-col xs sm="12" md lg xl="6">
          <b-form-group label="Valid Date Setting" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-1"
              v-model="form.valid_type_id"
              :options="validSettingOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>

        <b-col xs sm="12" md lg xl="6" v-if="form.valid_type_id == 2">
          <InputText
            class="mb-0"
            textFloat="Valid Days (After Redeemed Coupons)"
            placeholder="Valid Days (After Redeemed Coupons)"
            type="number"
            isRequired
            name="activated"
            v-model="form.activated"
            :v="v.form.activated"
            @blur="(val) => setDefault(val, index)"
          /> </b-col
      ></b-row>
    </template>

    <!-- Valid From - To -->
    <b-row
      class="mt-2"
      v-if="form.valid_type_id == 1 || form.availability == 1"
    >
      <b-col xs sm="12" md lg xl="6">
        <label class="col-form-label"
          >Valid From <span class="text-error">*</span></label
        >
        <div
          :class="[
            chkValid == 1 ? 'input-container input-disable' : 'input-container',
          ]"
        >
          <datetime
            type="datetime"
            :input-class="[
              v.form.start_datetime.$error
                ? 'border-error styleDatetime'
                : 'styleDatetime',
            ]"
            v-model="form.start_datetime"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            @input="handleMinDate"
            :disabled="chkValid == 1 ? true : false"
            ref="transferDateStart"
          >
          </datetime>
          <div
            :class="
              'icon-primary text-right ' + id == 0 ? '' : 'cursor-default'
            "
            @click="$refs.transferDateStart.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                chkValid == 1
                  ? 'cursor-default color-primary'
                  : 'pointer color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.start_datetime.$error" class="input-error">
          Please select date.
        </div>
      </b-col>
      <b-col xs sm="12" md lg xl="6" class="mt-3 mt-md-0">
        <label class="col-form-label">
          Valid To <span class="text-error">*</span>
        </label>
        <div class="input-container">
          <datetime
            type="datetime"
            :input-style="styleDatetime"
            :input-class="[v.form.end_datetime.$error ? 'border-error' : '']"
            v-model="form.end_datetime"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateEnd"
            :min-datetime="form.start_datetime"
          >
          </datetime>
          <div
            class="icon-primary text-right"
            @click="$refs.transferDateEnd.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.end_datetime.$error" class="input-error">
          Please select date.
        </div>
      </b-col>
    </b-row>
    <div class="no-gutters bg-white mt-3">
      <b-form-checkbox
        switch
        v-model="form.is_auto_mark_use"
        class="radio-active"
        size="lg"
        :value="1"
        :unchecked-value="0"
        @change="handleMarkUse"
      >
        <span class="ml-2 main-label">Auto Mark Use When Show Coupon Code</span>
      </b-form-checkbox>
    </div>
    <!--  -->
    <div class="no-gutters bg-white mt-3">
      <b-form-checkbox
        switch
        v-model="form.allow_customer_redeem"
        class="radio-active"
        size="lg"
        :value="1"
        :unchecked-value="0"
      >
        <span class="ml-2 main-label">{{
          form.allow_customer_redeem
            ? "Allow Coupon To Redeem By Customer"
            : "Deny Coupon To Redeem By Customer"
        }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import InputSelect from "@/components/inputs/InputSelect";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: {
    InputText,
    InputTextArea,
    TextEditorsTiny,
    UploadFile,
    PreviewBox,
    InputSelect,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    chkValid: {
      required: true,
      type: Number,
    },
    getPercent: {
      required: true,
      type: Boolean,
    },

    availabilityList: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    multiplyPointIcon: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    multiplyPoint() {
      if (this.multiplyPoint) {
        this.form.point = this.pointShow;
        this.form.multiply_point = 0;
      } else {
        this.form.multiply_point = this.pointShow;
        this.form.point = 0;
      }
    },
  },
  created() {
    if (this.form.image_url) this.showPreview = this.form.image_url;
    if (this.multiplyPoint) {
      this.pointShow = this.form.point;
    } else {
      this.pointShow = this.form.multiply_point;
    }
  },
  data() {
    return {
      filename: "",
      validSettingOptions: [
        { value: 2, text: "Valid (After Redeemed Coupons)" },
        { value: 1, text: "Specific Date" },
      ],
      couponTypeOptions: [
        { value: 1, text: "Fix Code" },
        { value: 2, text: "Import Code" },
      ],
      extraOptions: [
        { text: "Multiplier Point", value: 1 },
        { text: "Fixed Point", value: 2 },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isDisable: false,
      percentOrbath: this.getPercent,
      isLoadingImage: false,
      showPreview: "",
      multiplyPoint: this.multiplyPointIcon,
      pointShow: 0,
      isUpload: false,
    };
  },
  methods: {
    handleTypeChange(val) {
      if (val == 2) {
        this.form.availability = 0;
      }
    },
    handleMinDate() {
      let diff = this.$moment(this.form.end_datetime).diff(
        this.$moment(this.form.start_datetime),
        "min"
      );

      if (diff < 0) this.form.end_datetime = "";
    },
    process(input) {
      let value = input.value;
      let numbers = value.replace(/[^0-9]/g, "");
      input.value = numbers;
    },
    onPaste(event) {
      var characters = event.target.value;

      if (!/^\d+$/.test(characters)) {
        let value = characters;
        let numbers = value.replace(/[^0-9]/g, "");
        event.target.value = numbers;
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return evt.preventDefault();
      }
      return true;
    },
    onKeypressArticleNo(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45
      ) {
        // return allowedEng;
      } else {
        e.preventDefault();
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        let img = new Image();

        img.src = reader.result;
        img.onload = async () => {
          if (parseInt(img.height) !== parseInt(img.width)) {
            this.warningAlert("Please upload image with ratio 1:1 !");
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.images = await this.saveImagetoDb(reader.result);
            this.isLoadingImage = false;
            this.isDisable = false;
            this.form.image_url = this.images;
            this.showPreview = this.images;
          }
        };
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.image_url = "";
      this.showPreview = "";
    },
    getPercentClick(status) {
      this.percentOrbath = status;
      this.$emit("getPercent", this.percentOrbath);
    },
    getPromotionValue(event) {
      this.$emit("getPromotionValue", event.target.value);
    },
    onAddPoint(event) {
      if (this.multiplyPoint) {
        this.form.point = event.target.value;
      } else {
        this.form.multiply_point = event.target.value;
      }
    },
    onFileChange(file) {
      this.isUpload = true;
      this.form.coupon_code_import_name = file.name;

      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.coupon_code_import = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.isUpload = false;
      };
    },
    deleteFile(value) {
      this.form.coupon_code_import = null;
      this.form.coupon_code_import_name = null;
    },
    async downloadTemplate() {
      const res = await this.axios(
        `/coupon/getcouponcodetemplate`,

        {
          responseType: "blob",
        }
      );
      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "coupon-code-template.xlsx");
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    handleMarkUse(val) {
      if (val == 1) this.form.allow_customer_redeem = 1;
    },
    setDefault(val, index) {
      if (val.target.value == 0 || !val.target.value) this.form.activated = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-position {
  height: 100%;
  position: relative;
}
.percent {
  font-size: 13px;
}

::v-deep .border-error {
  border-color: red !important;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.input-disable {
  background-color: #fafafa;
}
::v-deep .vdatetime-input.styleDatetime {
  border: none;
}

.input-error {
  color: red;
}
.icon-multiple {
  font-size: 20px;
}
.padding-multiple {
  padding: 0 5px;
}
</style>
